exports.components = {
  "component---src-pages-2023-all-tsx": () => import("./../../../src/pages/2023-all.tsx" /* webpackChunkName: "component---src-pages-2023-all-tsx" */),
  "component---src-pages-2023-battersea-kensington-tsx": () => import("./../../../src/pages/2023-battersea-kensington.tsx" /* webpackChunkName: "component---src-pages-2023-battersea-kensington-tsx" */),
  "component---src-pages-2023-truman-brewery-tsx": () => import("./../../../src/pages/2023-truman-brewery.tsx" /* webpackChunkName: "component---src-pages-2023-truman-brewery-tsx" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-collections-tsx": () => import("./../../../src/pages/collections.tsx" /* webpackChunkName: "component---src-pages-collections-tsx" */),
  "component---src-pages-events-tsx": () => import("./../../../src/pages/events.tsx" /* webpackChunkName: "component---src-pages-events-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-tags-tsx": () => import("./../../../src/pages/tags.tsx" /* webpackChunkName: "component---src-pages-tags-tsx" */),
  "component---src-pages-themes-tsx": () => import("./../../../src/pages/themes.tsx" /* webpackChunkName: "component---src-pages-themes-tsx" */),
  "component---src-templates-collection-tsx": () => import("./../../../src/templates/collection.tsx" /* webpackChunkName: "component---src-templates-collection-tsx" */),
  "component---src-templates-event-tsx": () => import("./../../../src/templates/event.tsx" /* webpackChunkName: "component---src-templates-event-tsx" */),
  "component---src-templates-programme-tsx": () => import("./../../../src/templates/programme.tsx" /* webpackChunkName: "component---src-templates-programme-tsx" */),
  "component---src-templates-school-tsx": () => import("./../../../src/templates/school.tsx" /* webpackChunkName: "component---src-templates-school-tsx" */),
  "component---src-templates-student-tsx": () => import("./../../../src/templates/student.tsx" /* webpackChunkName: "component---src-templates-student-tsx" */),
  "component---src-templates-tag-tsx": () => import("./../../../src/templates/tag.tsx" /* webpackChunkName: "component---src-templates-tag-tsx" */),
  "component---src-templates-theme-tsx": () => import("./../../../src/templates/theme.tsx" /* webpackChunkName: "component---src-templates-theme-tsx" */)
}

