import React from "react";

import classNames from "classnames";
import PropTypes from "prop-types";

import { Icon } from "../Icon";
import * as styles from "./PlusIcon.module.scss";

interface PlusIconProps {
  startScale?: number;
  scale?: number;
  iconProps?: object;
  toggle?: boolean;
  className?: string | null;
}

const PlusIcon: React.FC<PlusIconProps> = ({
  startScale = 1,
  scale = 0,
  iconProps = {},
  toggle = false,
  className,
}) => {
  return (
    <span
      className={classNames(
        styles.wrapper,
        { [styles.toggled]: toggle },
        className,
      )}
      style={{ "--scale-total": scale, "--scale-start": startScale }}
    >
      <Icon name="plus" {...iconProps} />
    </span>
  );
};

export { PlusIcon };
