/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { useRef, useEffect, ReactNode } from "react";
import { useRCAContext } from "../../context/RCAContext";
import { useStaticQuery, graphql } from "gatsby";

import { Footer } from "../navigation/Footer";
import { Header } from "../navigation/Header";
import { PageFooter } from "../navigation/PageFooter";
// import { ProjectsFilter } from "../navigation/ProjectsFilter/index.tsx.keep";
// import { StudentsFilter } from "../navigation/StudentsFilter";

// KEEP BELOW
// import { ChatAppMount } from "../pagelayout/ChatAppMount"
import { ServiceWorkerRefresh } from "../pagelayout/ServiceWorkerRefresh";

import setScrollBarWidth from "set-scrollbar-width";

import * as styles from "./Layout.module.scss";

interface LayoutProps {
  location: { pathname: string };
  pageContext: { programmes: [{ label: string; slug: string; code: string }] };
  children?: ReactNode;
}

const Layout: React.FC<LayoutProps> = ({ location, children, pageContext }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  const { showMode, setShowMode } = useRCAContext();

  // depending on the path change the showMode
  useEffect(() => {
    if (location.pathname.includes("2023-all")) {
      setShowMode("all");
    } else if (location.pathname.includes("2023-battersea-kensington")) {
      setShowMode("battersea-kensington");
    } else if (location.pathname.includes("2023-truman-brewery")) {
      setShowMode("truman-brewery");
    }
  }, [location.pathname]);

  const layoutHeaderRef = useRef(null);

  useEffect(() => {
    setScrollBarWidth();
  }, []);

  return (
    <>
      <a href="#maincontent" className={styles.skip} tabIndex={0}>
        Skip to main content
      </a>

      <div ref={layoutHeaderRef} />

      <Header siteTitle={data.site.siteMetadata.title} location={location} />
      {/* 
      <ProjectsFilter location={location} />
      <StudentsFilter location={location} pageContext={pageContext} /> */}

      <div className={styles.container} key={"container-" + location.pathname}>
        <main id="maincontent">{children}</main>

        <PageFooter />
      </div>

      <Footer location={location} />
      <ServiceWorkerRefresh />

      {/* <ChatAppMount /> */}
    </>
  );
};

export { Layout };
