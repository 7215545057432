import React, { useCallback } from "react";

import { connectStateResults, Highlight } from "react-instantsearch-dom";

import { ListItem } from "../common/ListItem";
import * as styles from "./Search.module.scss";

const Results = connectStateResults(
  ({ searchState, searchResults, children, title }) => {
    if (!searchResults || searchResults.nbHits === 0) {
      return (
        <ListItem
          topBorder={false}
          leftBorder={false}
          rightBorder={false}
          bottomBorder={false}
          size="navigation"
          invert
        >
          No results for '{searchState.query}' in {title}
        </ListItem>
      );
    }

    return children(searchResults);
  },
);

const getAttributeName = (name) => {
  switch (name) {
    case "students":
      return "displayName";
    case "projects":
      return "displayName";
    case "programmes":
      return "programmeName";
    case "events":
      return "eventName";
    case "articles":
      return "title";
    case "collections":
      return "collectionName";
    default:
      return "";
  }
};

const getLink = (name, hit) => {
  switch (name) {
    case "students":
      return `/students/${hit.slug}`;
    case "programmes":
      return `/programmes/${hit.slug.current}`;
    case "events":
      return `/events/${hit.slug.current}`;
    case "articles":
      return `/features/${hit.slug.current}`;
    case "collections":
      return `/collections/${hit.slug.current}`;
    default:
      return "";
  }
};

const ResultsList = ({ name, title }) => {
  const filteredHits = useCallback(
    (hits) => {
      switch (name) {
        case "students":
          return hits.filter((h) => h.displayName);
        case "programmes":
          return hits.filter((h) => h.programmeName);
        case "events":
          return hits.filter((h) => h.eventName);
        case "collections":
          return hits.filter((h) => h.collectionName);
        default:
          return hits;
      }
    },
    [name],
  );

  return (
    <Results title={title}>
      {(resultsList) => {
        return (
          <>
            <header className={styles.resultsTitle}>
              <ListItem
                as="h2"
                active
                slot={
                  <span>{filteredHits(resultsList.hits).length} Results</span>
                }
                leftBorder={false}
                rightBorder={false}
                size="navigation"
                invert
              >
                {title}
              </ListItem>
            </header>

            <ul className="list-reset">
              {filteredHits(resultsList.hits).map((hit) => (
                <ListItem
                  key={getLink(name, hit)}
                  to={getLink(name, hit)}
                  leftBorder={false}
                  rightBorder={false}
                  topBorder={false}
                  size="navigation"
                  invert
                  subMenuLevel="one"
                  className={styles.resultsItem}
                >
                  <Highlight
                    attribute={getAttributeName(name)}
                    hit={hit}
                    tagName="mark"
                  />
                </ListItem>
              ))}
            </ul>
          </>
        );
      }}
    </Results>
  );
};

export { ResultsList };
