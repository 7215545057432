import React, { useState, useEffect, useRef, useCallback } from "react";

import classNames from "classnames";
import { useStaticQuery, graphql } from "gatsby";

import { MENUS, useRCAContext } from "../../../context/RCAContext";
import { clickOutside } from "../../../utils/common";
import { Collapsible } from "../../common/Collapsible";
import { ListItem } from "../../common/ListItem";
import { PlusIcon } from "../../common/PlusIcon";
import { StandardGrid } from "../../common/StandardGrid";
import * as styles from "./Menu.module.scss";
// import { SchoolMenuItem } from "./SchoolMenuItem";

const STATIC_LINKS = [
  // {
  //   to: "/programmes",
  //   label: "Show One",
  // },
  // {
  //   to: "/programmes",
  //   label: "Show Two",
  // },
  // {
  //   to: "/programmes",
  //   label: "Programmes",
  // },
  // {
  //   to: "/students",
  //   label: "Students",
  // },
  // {
  //   to: "/events",
  //   label: "Events",
  // },
  {
    to: "/events",
    label: "Events",
  },
  {
    to: "/themes",
    label: "Themes",
  },
  {
    to: "/collections",
    label: "Collections",
  },
  // {
  //   to: "/articles",
  //   label: "Articles",
  // },
  {
    to: "/tags",
    label: "Tags",
  },
  {
    to: "https://www.rca.ac.uk/",
    label: "About RCA",
  },
];

const STATIC_SHOW_ONE_LINKS = [
  {
    to: "/2023-truman-brewery/",
    label: "Introduction",
  },
  {
    to: "/2023-truman-brewery/#programmes",
    label: "Participating Programmes",
  },
  {
    to: "/2023-truman-brewery/#venue",
    label: "Venue",
  },
  {
    to: "/2023-truman-brewery/#events",
    label: "Events",
  },
  {
    to: "/2023-truman-brewery/#theme",
    label: "Spotlight Theme",
  },
  {
    to: "/2023-truman-brewery/#collections",
    label: "Collections",
  },
  // {
  //   to: "/articles",
  //   label: "Articles",
  // },
];

const STATIC_SHOW_TWO_LINKS = [
  {
    to: "/2023-battersea-kensington/",
    label: "Introduction",
  },
  {
    to: "/2023-battersea-kensington/#programmes",
    label: "Participating Programmes",
  },
  {
    to: "/2023-battersea-kensington/#venue",
    label: "Venues",
  },
  {
    to: "/2023-battersea-kensington/#events",
    label: "Events",
  },
  {
    to: "/2023-battersea-kensington/#theme",
    label: "Spotlight Theme",
  },
  {
    to: "/2023-battersea-kensington/#collections",
    label: "Collections",
  },
  // {
  //   to: "/articles",
  //   label: "Articles",
  // },
];

const STATIC_SHOW_ALL_LINKS = [
  {
    to: "/2023-all/",
    label: "Introduction",
  },
  {
    to: "/2023-all/#programmes",
    label: "All Programmes",
  },
  {
    to: "/2023-all/#events",
    label: "Events",
  },
  {
    to: "/2023-all/#theme",
    label: "Spotlight Theme",
  },
  {
    to: "/2023-all/#collections",
    label: "Collections",
  },
  // {
  //   to: "/articles",
  //   label: "Articles",
  // },
];

const Menu = ({ onReportRef, animating }) => {
  const { isNavOpen, closeMenu, currentMenuRef, bodyLocked } = useRCAContext();
  const listRef = useRef(null);

  const data = useStaticQuery(graphql`
    query SchoolsMenuQuery {
      allSanitySchool(sort: { schoolName: ASC }) {
        nodes {
          schoolName
          slug {
            current
          }
          programmes {
            programmeName
            slug {
              current
            }
          }
        }
      }
    }
  `);

  const [schoolsSubmenu, setSchoolsSubmenu] = useState(false);
  const [showOneSubmenu, setShowOneSubmenu] = useState(false);
  const [showTwoSubmenu, setShowTwoSubmenu] = useState(false);
  const [showAllSubmenu, setShowAllSubmenu] = useState(false);

  const schools = data.allSanitySchool.nodes;

  /***** NOTE NOTE *******/
  // The call to closeMenu in this useEffect causes menu not to work
  useEffect(() => {
    // if current click is the menu button return
    // if (animating) return

    if (isNavOpen && listRef.current) {
      return clickOutside(listRef.current, () => {
        if (currentMenuRef.current !== MENUS.SEARCH) {
          closeMenu();
        }
      });
    }
  }, [isNavOpen, closeMenu, currentMenuRef, animating]);

  const onRef = useCallback(
    (ref) => {
      listRef.current = ref;

      onReportRef(ref);
    },
    [onReportRef],
  );

  return (
    <StandardGrid
      tagName="nav"
      className={classNames(styles.menu, { [styles.bodyLocked]: bodyLocked })}
      // Not sure why this is here as a boolean??? causes an error
      // onClick
    >
      <ul ref={onRef} className={styles.items}>
        <li>
          <ListItem
            to="/"
            rightBorder={false}
            leftBorder={false}
            topBorder={false}
            size="navigation"
          >
            Home
          </ListItem>
        </li>
        <li>
          <ListItem
            rightBorder={false}
            leftBorder={false}
            topBorder={false}
            size="navigation"
            onClick={() => {
              setShowAllSubmenu(!showAllSubmenu);
              setShowOneSubmenu(false);
              setShowTwoSubmenu(false);
            }}
            slot={<PlusIcon toggle={showAllSubmenu} name="plus" />}
            // className={styles.showAll}
          >
            2023 All Students
          </ListItem>

          <ul className="list-reset">
            <Collapsible duration={250} open={showAllSubmenu}>
              {STATIC_SHOW_ALL_LINKS.map((item, index) => (
                <li key={`${item.to}-${index}`}>
                  <ListItem
                    to={item.to}
                    rightBorder={false}
                    leftBorder={false}
                    topBorder={false}
                    size="navigation"
                    icon="chevron"
                    reverse
                    justifyContent="flex-end"
                    subMenuLevel="Two"
                  >
                    {item.label}
                  </ListItem>
                </li>
              ))}
            </Collapsible>
          </ul>
        </li>
        <li>
          <ListItem
            rightBorder={false}
            leftBorder={false}
            topBorder={false}
            size="navigation"
            onClick={() => {
              setShowTwoSubmenu(!showTwoSubmenu);
              setShowOneSubmenu(false);
              setShowAllSubmenu(false);
            }}
            slot={<PlusIcon toggle={showTwoSubmenu} name="plus" />}
            // className={styles.showTwo}
          >
            2023 Battersea & Kensington
          </ListItem>

          <ul className="list-reset">
            <Collapsible duration={250} open={showTwoSubmenu}>
              {STATIC_SHOW_TWO_LINKS.map((item, index) => (
                <li key={`${item.to}-${index}`}>
                  <ListItem
                    to={item.to}
                    rightBorder={false}
                    leftBorder={false}
                    topBorder={false}
                    size="navigation"
                    icon="chevron"
                    reverse
                    justifyContent="flex-end"
                    subMenuLevel="Two"
                  >
                    {item.label}
                  </ListItem>
                </li>
              ))}
            </Collapsible>
          </ul>
        </li>
        <li>
          <ListItem
            rightBorder={false}
            leftBorder={false}
            topBorder={false}
            size="navigation"
            onClick={() => {
              setShowOneSubmenu(!showOneSubmenu);
              setShowTwoSubmenu(false);
              setShowAllSubmenu(false);
            }}
            slot={<PlusIcon toggle={showOneSubmenu} name="plus" />}
            // className={styles.showOne}
          >
            2023 Truman Brewery
          </ListItem>

          <ul className="list-reset">
            <Collapsible duration={250} open={showOneSubmenu}>
              {STATIC_SHOW_ONE_LINKS.map((item, index) => (
                <li key={`${item.to}-${index}`}>
                  <ListItem
                    to={item.to}
                    rightBorder={false}
                    leftBorder={false}
                    topBorder={false}
                    size="navigation"
                    icon="chevron"
                    reverse
                    justifyContent="flex-end"
                    subMenuLevel="Two"
                  >
                    {item.label}
                  </ListItem>
                </li>
              ))}
            </Collapsible>
          </ul>
        </li>
        <li>
          <ListItem
            rightBorder={false}
            leftBorder={false}
            topBorder={false}
            size="navigation"
            onClick={() => setSchoolsSubmenu(!schoolsSubmenu)}
            slot={<PlusIcon toggle={schoolsSubmenu} name="plus" />}
          >
            Schools
          </ListItem>

          <ul className="list-reset">
            <Collapsible duration={250} open={schoolsSubmenu}>
              {schools.map((school, index) => (
                <ListItem
                  key={`${school.slug.current}-${index}`}
                  to={`/schools/${school.slug.current}`}
                  leftBorder={false}
                  topBorder={false}
                  rightBorder={false}
                  size="navigation"
                  icon="chevron"
                  reverse
                  justifyContent="flex-end"
                  subMenuLevel="Two"
                >
                  {school.schoolName}
                </ListItem>
              ))}
            </Collapsible>
          </ul>
        </li>
        {STATIC_LINKS.map((item, index) => (
          <li key={`${item.to}-${index}`}>
            <ListItem
              to={item.to}
              rightBorder={false}
              leftBorder={false}
              topBorder={false}
              size="navigation"
            >
              {item.label}
            </ListItem>
          </li>
        ))}
      </ul>
    </StandardGrid>
  );
};

export { Menu };
