import React, { useMemo, ReactNode, ElementType } from "react";

import classNames from "classnames";

import { iconKeys, Icon } from "../Icon";
import { InternalExternalLink } from "../InternalExternalLink";
import * as styles from "./ListItem.module.scss";

interface ListItemProps {
  reverse?: boolean;
  children?: ReactNode;
  icon?: string;
  to?: string;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  leftBorder?: boolean;
  rightBorder?: boolean;
  topBorder?: boolean;
  bottomBorder?: boolean;
  indent?: boolean;
  size?: "normal" | "large" | "navigation";
  tagName?: ElementType;
  slot?: ReactNode;
  subMenuLevel?: string;
  invert?: boolean;
  noHover?: boolean;
  noFocus?: boolean;
  justifyContent?: string;
  className?: string;
  contentClass?: string;

  props?: object;
}

const iconSize = {
  normal: "normal",
  large: "large",
};

const justifyCls = {
  "flex-end": styles.justifyEnd,
};

const ListItem: React.FC<ListItemProps> = ({
  reverse,
  children,
  icon,
  to,
  onClick,
  leftBorder = true,
  rightBorder = true,
  topBorder = true,
  bottomBorder = true,
  indent = false,
  size = "normal",
  tagName: El = "p",
  slot = null,
  subMenuLevel,
  invert = false,
  noHover = false,
  noFocus = false,
  justifyContent,
  className,
  contentClass,
  ...props
}) => {
  const cls = classNames(
    styles.item,
    [styles[size]],
    [styles[`subMenu${subMenuLevel}`]],
    [justifyCls[justifyContent as keyof object]],
    {
      [styles.invert]: invert,
      [styles.reverse]: reverse,
      [styles.topBorder]: topBorder,
      [styles.rightBorder]: rightBorder,
      [styles.bottomBorder]: bottomBorder,
      [styles.leftBorder]: leftBorder,
      [styles.indent]: indent,
      "no-hover": noHover,
      [styles.noFocus]: noFocus,
    },
    className,
  );

  const itemType = useMemo(() => {
    if (onClick) {
      return "button";
    }

    if (to) {
      return "link";
    }

    return "normal";
  }, [to, onClick]);

  const innerContent = useMemo(
    () => (
      <>
        <span className={classNames(styles.content, contentClass)}>
          {children}
        </span>
        {(icon || slot) && (
          <span className={styles.iconWrapper}>
            {slot}

            {icon && (
              <Icon className={styles.icon} name={icon} size={iconSize[size]} />
            )}
          </span>
        )}
      </>
    ),
    [icon, children, size, slot],
  );

  if (itemType === "button") {
    return (
      <button className={cls} onClick={onClick} {...props}>
        {innerContent}
      </button>
    );
  }

  if (itemType === "link") {
    return (
      <InternalExternalLink className={cls} to={to}>
        {innerContent}
      </InternalExternalLink>
    );
  }

  return (
    <El className={cls} {...props}>
      {innerContent}
    </El>
  );
};

export { ListItem };
