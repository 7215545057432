import React from "react"; // eslint-disable-line no-unused-vars

import classNames from "classnames";

import { ReactComponent as ArrowLeftIcon } from "../../../images/icons/arrow-left.svg";
import { ReactComponent as ArrowRightIcon } from "../../../images/icons/arrow-right.svg";
import { ReactComponent as ArrowDownIcon } from "../../../images/icons/arrow-down.svg";
import { ReactComponent as ChevronDownIcon } from "../../../images/icons/chevron-down.svg";
import { ReactComponent as ChevronIcon } from "../../../images/icons/chevron.svg";
import { ReactComponent as CircleArrow } from "../../../images/icons/circle-arrow.svg";
import { ReactComponent as LinkArrowIcon } from "../../../images/icons/link-arrow.svg";
import { ReactComponent as LinkArrow } from "../../../images/icons/link-arrow.svg";
import { ReactComponent as LinkIcon } from "../../../images/icons/link.svg";
import { ReactComponent as MinusIcon } from "../../../images/icons/minus.svg";
import { ReactComponent as PlusIcon } from "../../../images/icons/plus.svg";
import { ReactComponent as SearchIcon } from "../../../images/icons/search.svg";
import { ReactComponent as SelectArrowIcon } from "../../../images/icons/select-arrow.svg";
import { ReactComponent as SmallPlusIcon } from "../../../images/icons/small-plus.svg";
import { ReactComponent as TickIcon } from "../../../images/icons/tick.svg";
import { ReactComponent as XIcon } from "../../../images/icons/x.svg";
import * as styles from "./Icon.module.scss";

interface iconMapProps {
  name?: string;
  size?: string;
  className?: string;
  props?: object;
}

const iconMap = {
  search: SearchIcon,
  plus: PlusIcon,
  "small-plus": SmallPlusIcon,
  minus: MinusIcon,
  chevron: ChevronIcon,
  "chevron-down": ChevronDownIcon,
  "arrow-left": ArrowLeftIcon,
  "arrow-right": ArrowRightIcon,
  "arrow-down": ArrowDownIcon,
  tick: TickIcon,
  x: XIcon,
  link: LinkIcon,
  "link-arrow-icon": LinkArrowIcon,
  "select-arrow": SelectArrowIcon,
  "circle-arrow": CircleArrow,
  "link-arrow": LinkArrow,
};

export const iconKeys = Object.keys(iconMap);

const Icon: React.FC<iconMapProps> = ({
  name = "minus",
  size = "normal",
  className,
  ...props
}) => {
  if (!iconKeys.includes(name)) {
    return null;
  }

  const IconName: any = iconMap[name as keyof object];

  return (
    <span
      className={classNames(styles.icon, [styles[size]], className)}
      {...props}
    >
      <IconName />
    </span>
  );
};

export { Icon };
