import PlaceholderImage from "../images/trans.png";

export const placeholderImageFluid = {
  aspectRatio: 1.6,
  src: PlaceholderImage,
  srcSet: `${PlaceholderImage} 1w`,
  sizes: "(max-width: 1px) 100vw, 1px",
};

export const clickOutside = (el, onClickOutside, validator) => {
  const cb = (e) => {
    if (validator) {
      if (validator(el, e)) {
        onClickOutside();
      }
    } else if (e.target !== el && !el.contains(e.target)) {
      onClickOutside();
    }
  };

  document.addEventListener("click", cb);
  const unregisterExits = registerExits(onClickOutside);

  return () => {
    unregisterExits();
    document.removeEventListener("click", cb);
  };
};

const KEYS = {
  ESCAPE: 27,
};

export const registerExits = (onEscape) => {
  const cb = (e) => {
    if ([KEYS.ESCAPE].includes(e.keyCode)) {
      onEscape();
    }
  };

  document.addEventListener("keyup", cb);
  return () => document.removeEventListener("keyup", cb);
};

export const shuffle = (arr) => {
  return [...arr].sort(() => Math.random() - 0.5);
};

export const detectTouched = (cb) => {
  const onTouchStart = () => {
    cb(true);
  };

  const onTouchEnd = () => {
    cb(false);
  };

  document.addEventListener("touchstart", onTouchStart);
  document.addEventListener("touchend", onTouchEnd);

  return () => {
    document.removeEventListener("touchstart", onTouchStart);
    document.removeEventListener("touchend", onTouchEnd);
  };
};

export const isIOS = () =>
  (/iPad|iPhone|iPod/.test(navigator.platform) ||
    (navigator.platform === "MacIntel" && navigator.maxTouchPoints > 1)) &&
  !window.MSStream;

export const getCSSCustomProp = (
  propKey,
  element = document.documentElement,
  castAs = "string",
) => {
  let response = getComputedStyle(element).getPropertyValue(propKey);

  // Tidy up the string if there's something to work with
  if (response.length) {
    response = response.replace(/'|"/g, "").trim();
  }

  // Convert the response into a whatever type we wanted
  switch (castAs) {
    case "number":
    case "int":
      return parseInt(response, 10);
    case "float":
      return parseFloat(response, 10);
    case "boolean":
    case "bool":
      return response === "true" || response === "1";
    default:
      return response;
  }
};

export const loadImage = (src) => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = () => {
      resolve(img);
    };
    img.onerror = () => {
      reject();
    };
    img.src = src;
  });
};

// program to generate random strings

// declare all characters
const characters =
  "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

export const generateRandomString = (length) => {
  let result = " ";
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }

  return result;
};

export const titleCase = (str) => {
  var splitStr = str?.toLowerCase().split(" ");
  for (var i = 0; i < splitStr.length; i++) {
    // You do not need to check if i is larger than splitStr length, as your for does that for you
    // Assign it back to the array
    splitStr[i] =
      splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
  }
  // Directly return the joined string
  return splitStr.join(" ");
};

export const startsWithLowercase = (word) => {
  return word.charAt(0) !== word.charAt(0).toUpperCase();
};

export const capitalize = (s) => {
  return s[0]?.toUpperCase() + s.slice(1);
};

export const toSentenceCase = (str) => {
  return (
    str?.toLowerCase().charAt(0).toUpperCase() + str?.toLowerCase().slice(1)
  );
};
